<template>
  <div class="home">
    <v-app>
      <v-content>
        <section id="hero">
          <v-row no-gutters>
            <v-img
                :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
                :src="require('../assets/physiotherapy.webp')"
            >
              <v-theme-provider dark>
                <v-container fill-height>
                  <v-row
                      align="center"
                      class="mx-auto"
                      justify="center"
                  >
                    <v-col
                        class="text-center"
                        cols="12"
                        tag="h1"
                    >
                    <span
                        class="font-weight-light"
                        :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                    >
                      Witaj w
                    </span>

                      <br>

                      <span
                          :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                          class="font-weight-black primary--text"
                      >
                     FIZJOMOVE
                    </span>

                    </v-col>

                    <v-btn
                        class="align-self-end"
                        fab
                        outlined
                        color="black"
                        @click="$vuetify.goTo('#about-me')"
                    >
                      <v-icon>mdi-chevron-double-down</v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-theme-provider>
            </v-img>
          </v-row>
        </section>
        <section id="about-me">
          <div class="py-6"></div>

          <v-container class="text-center">
            <h2 class="display-2 font-weight-bold mb-3">
              O Nas
            </h2>
            <v-responsive
                class="mx-auto title font-weight-light mb-8 text-left home-about_text"
                max-width="720"
            >
              Jesteśmy podmiotem leczniczym działającym od 2018 roku. Współpracujemy z lekarzami                                  i fizjoterapeutami w ramach fizjoterapii pacjentów w warunkach domowych. Posiadamy kontrakt na te świadczenia z Narodowym Funduszem Zdrowia. Jeżeli macie Państwo wskazania do fizjoterapii            w domu lub posiadacie orzeczenie o niepełnosprawności w stopniu znacznym (lub równoważne) - skontaktujcie się z Nami-zaoferujemy fachową i jak najszybszą pomoc.
            </v-responsive>
            <div class="py-6"></div>
            <v-row align="center"
                   class="mx-auto"
                   justify="center">
              <v-col
                  cols="12"
                  md="4"
              >
                <div class="text-center">
                  <a target="_blank"
                     href="/certificate"
                     rel="nofollow">
                    <img src="../assets/kapitalna-firm_logo.jpg" style="width: 200px;" alt="kapitala firma logo"/>
                  </a>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <a target="_blank"
                   href="https://wizytowka.rzetelnafirma.pl/VK100KZD"
                   rel="nofollow">
                  <img title="Kliknij i sprawdź status certyfikatu" alt=""
                       src="https://aktywnybaner.rzetelnafirma.pl/ActiveBanner/GetActiveBannerImage/13/VK100KZD"
                       style="border:none;">
                </a>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <div class="text-center">
                  <img src="../assets/nfz_logo.jpg" style="width: 200px;" alt="nfz logo"/>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <div class="py-12"></div>
        </section>
        <section
            id="features"
            class="grey lighten-3"
        >

          <v-container class="text-center">
            <v-responsive
                class="mx-auto mb-12"
                width="56"
            >
              <v-divider class="mb-1"></v-divider>

              <v-divider></v-divider>
            </v-responsive>

            <v-row>
              <v-col
                  v-for="({ icon, title, text }, i) in features"
                  :key="i"
                  cols="12"
                  md="4"
              >
                <v-card
                    class="py-12 px-4"
                    color="grey lighten-5"
                    flat
                >
                  <v-theme-provider dark>
                    <div>
                      <v-avatar
                          color="primary"
                          size="88"
                      >
                        <v-icon
                            large
                            v-text="icon"
                        ></v-icon>
                      </v-avatar>
                    </div>
                  </v-theme-provider>

                  <v-card-title
                      class="justify-center font-weight-black text-uppercase"
                      v-text="title"
                  ></v-card-title>

                  <v-card-text
                      class="subtitle-1"
                      v-text="text"
                  >
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <div class="py-12"></div>
        </section>
        <section id="stats">
          <v-img
              :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
              :src="require('../assets/physiotherapy_2.webp')"
          >
          </v-img>
        </section>
        <section
            id="contact"
            class="grey lighten-3"
        >
          <div class="py-6"></div>
          <v-row
              align="center"
              class="mx-auto pb-12"
              justify="center"
          >
            <v-col lg="4">
              <div class="text-center">
                  <img src="../assets/logo.webp" style="width: 300px;"/>
                </div>
            </v-col>
            <v-col lg="4">
              <p class="title font-weight-light mb-3">
                Kontakt:<br>
                Mail: biuro@fizjomove.pl<br>
                Tel: +48 796920851 <br>
                Godziny otwarcia: 7-22 pon-sob <br> <br>
                Dane rejestrowe spółki: <br>
                Fizjomove Sp. z o.o. <br>
                Ul. 3 Maja 8 <br>
                08-110 Siedlce <br>
                Nip: 8212660277<br>
                Regon: 381557003<br>
                KRS: 0000752928
              </p>
            </v-col>
            <v-col lg="4">
              <p class="title font-weight-light mb-3">
                Adres korespondencyjny: <br>
                Fizjomove Sp. z o.o. <br>
                Ul. Włodarzewska 83/48 <br>
                02-393 Warszawa <br>

                Biuro Fizjoterapii domowej: <br>
                Ul. Wyszków 28 <br>
                07-100 Wyszków <br>

              </p>
            </v-col>
          </v-row>

          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2445.232961487552!2d20.946544150525334!3d52.20281386734128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471934aaedb0ffd1%3A0xc5cc2b18614ed6d9!2sW%C5%82odarzewska%2083%2C%2002-393%20Warszawa!5e0!3m2!1spl!2spl!4v1608132858046!5m2!1spl!2spl"
              width="100%" height="400px" frameborder="0" style="border:0;" allowfullscreen aria-hidden="false"></iframe>
        </section>
        <v-footer
            color="#333333"
            padless
        >
          <v-row
              justify="center"
              no-gutters
          >
            <v-col
                class="py-4 text-center white--text"
                cols="12"
            >
              {{ new Date().getFullYear() }} — <strong>Standev</strong>
            </v-col>
          </v-row>
        </v-footer>
      </v-content>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    features: [
      {
        icon: 'mdi-account-group-outline',
        title: 'Zespół',
        text: 'Nasz zespół to grupa wyspecjalizowanych fizjoterapeutów, gotowych sprostać Państwa natrudniejszym dolegliwościom',
      },
      {
        icon: 'mdi-emoticon-happy-outline',
        title: 'Indywidualne podejście',
        text: 'Zależy nam na znalezieniu optymalnej dla Ciebie terapii, która zagwarantuje pełny powrót do zdrowia!',
      },
      {
        icon: 'mdi-check',
        title: 'Pełne zaangażowanie',
        text: 'Jesteście w miejscu, które na wskroś przesiąknięte jest ideą rehabilitacji i pomaganiu Pacjentom!',
      },
    ],
    stats: [
      ['24k', 'Github Stars'],
      ['330+', 'Releases'],
      ['1m', 'Downloads/mo'],
      ['5m', 'Total Downloads'],
    ],
  }),
}
</script>
<style scoped type="text/scss">
.home-about_text{
  text-align: justify;
  text-justify: auto;
}

</style>
