<template>
  <v-app>

    <v-app-bar
        app
    >

      <v-toolbar-title>
        <div class="d-flex align-center">
          <v-img
              alt="Fizjomove Logo"
              class="shrink mr-2"
              contain
              :src="require('./assets/logo.webp')"
              transition="scale-transition"
              width="140"
          />
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down primary--text">
        <v-btn
            text
            color="primary"
            @click="$vuetify.goTo('#about-me')"
        >
          <span class="mr-2">O Nas</span>
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="$vuetify.goTo('#contact')">
          <span class="mr-2">Kontakt</span>
        </v-btn>
<!--        <v-btn
            color="primary"
            text>
          <span class="mr-2">Rejestracja pacjenta</span>
        </v-btn>-->
      </v-toolbar-items>
      <v-btn
          class="mx-5 hidden-md-and-up"
          fab
          small
          color="white"
          href="tel:+48796920851"
      >
        <v-icon dark>
          mdi-phone
        </v-icon>
      </v-btn>
      <v-btn
          fab
          small
          @click.stop="dialog = !dialog"
          class="mx-2 hidden-md-and-up"
          color="white"
      >
        <v-icon dark>
          mdi-menu
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          max-width="350"
          max-height="500"
      >
        <v-card>
          <v-card-text>
            <v-row justify="center">
              <div class="d-flex align-center text-center" style="margin: 2em">
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="https://i.imgur.com/geCBqGY.png"
                    transition="scale-transition"
                    width="140"
                />
              </div>
            </v-row>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-btn
                      text
                      color="primary"
                      style="width: 100%"
                      @click="closeMobileDialog('#about-me')"
                  >
                    <span class="mr-2">O Nas</span>
                  </v-btn>
                </v-list-item-title>
                <v-list-item-title class="mt-5">
                  <v-btn
                      text
                      color="primary"
                      style="width: 100%"
                      @click="closeMobileDialog('#contact')"
                  >
                    <span class="mr-2">Kontakt</span>
                  </v-btn>
                </v-list-item-title>
<!--                <v-list-item-title class="mt-5">
                  <v-btn
                      text
                      color="primary"
                      style="width: 100%"
                      @click="dialog = !dialog"
                  >
                    <span class="mr-2">Rejestracja pacjent</span>
                  </v-btn>
                </v-list-item-title>-->
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-main>
      <Home/>
    </v-main>
  </v-app>
</template>

<script>
import Home from "@/views/Home";

export default {
  name: 'App',
  components: {
    Home
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    closeMobileDialog(id) {
      this.dialog = !this.dialog;
      this.$vuetify.goTo(id)
    }
  }
};
</script>
<style>
.v-main .v-content {
  padding: 0 !important;
}
</style>
